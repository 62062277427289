const initialState = {
  activeData: {
    data: [],
    tickValues: {},
    activeDataType: null,
    isLoading: true,
  },
};

const controlPanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GOT_ACTIVE_DATA':
      return {
        ...state,
        activeData: {
          data: action.data,
          tickValues: action.tickValues,
          activeDataType: action.activeDataType,
          isLoading: action.isLoading,
        },
      };
    default:
      return state;
  }
};

export default controlPanelReducer;
