import { groupLogsByDate } from '@time-helpers';

/*
 * 2+ different log with the same "cookie_id" is equal to 1 returning user.
 */
/**
 * Returning QR Logs
 * @param {Array} logs - QR Logs.
 * @returns {Array} - It returns returning qr logs.
 */

export const getReturningQrLogs = (logs) => {
  const returningLogs = [],
    cookieList = [],
    savedCookieList = [];

  for (const log of logs) {
    // Same cookie is found for the third time, no need to process again.
    if (savedCookieList.includes(log.cookie_id)) continue;
    // It is a new cookie, save it and skip to next loop.
    if (!cookieList.includes(log.cookie_id)) {
      cookieList.push(log.cookie_id);
      continue;
    }
    // Same cookie is found for the second time, take it.
    returningLogs.push(log);
    savedCookieList.push(log.cookie_id);
  }

  return returningLogs;
};

/**
 * Unique Qr Logs
 * @param {Array} logs - QR Logs.
 * @returns {Array} - It returns unique qr logs.
 */

export const getUniqueQrLogs = (logs) => {
  const uniqueLogs = [],
    cookieList = [];

  for (const log of logs) {
    if (cookieList.includes(log.cookie_id)) continue;
    uniqueLogs.push(log);
    cookieList.push(log.cookie_id);
  }
  return uniqueLogs;
};

/**
 * Qr Log Details
 * @param {object} qr - QR List.
 * @returns {object} - Total, Unique and returning qr details based on qr details.
 */

export const getQrLogDetails = (qr) => {
  const allLogsByDate = groupLogsByDate(qr.logs);
  const uniqueLogs = getUniqueQrLogs(qr.logs);
  const returningLogs = getReturningQrLogs(qr.logs);
  return {
    uuid: qr.uuid,
    logs: {
      // Grouped logs by date.
      total: allLogsByDate,
      unique: groupLogsByDate(uniqueLogs, allLogsByDate),
      returning: groupLogsByDate(returningLogs, allLogsByDate),
    },
  };
};
