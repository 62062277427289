const initialState = {
  matchedProducts: [],
  categoryBasedSkuInfo: [],
  categoryBasedConnectionInfo: [],
  rawOdooProducts: [],
  isLoading: true,
};

const odooProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FILTERED_MATCHED_PRODUCTS': {
      return {
        ...state,
        matchedProducts: action.matchedProducts,
        categoryBasedSkuInfo: action.skuData,
        categoryBasedConnectionInfo: action.connectionData,
        rawOdooProducts: action.rawOdooProducts,
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default odooProductsReducer;
