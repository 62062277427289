// **  Initial State
const initialState = {
  userData: {},
  companyInfo: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        userData: action.data,
      };
    case 'LOGOUT': {
      const obj = { ...action };
      delete obj.type;
      return { ...state, userData: {}, companyInfo: {}, ...obj };
    }
    case 'GOT_COMPANY_INFO':
      return {
        ...state,
        companyInfo: action.data,
      };
    default:
      return state;
  }
};

export default authReducer;
