const initialState = {
  loading: false,
  data: {},
};

const templateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DATA':
      return {
        ...state,
        loading: true,
      };
    case 'SET_DATA':
      return {
        ...state,
        data: action.data || {},
        loading: false,
      };
    case 'UPDATE_DATA':
      return {
        ...state,
        data: {
          ...state.data,
          [action.key]: action.value,
        },
      };
    case 'POST_DATA':
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default templateReducer;
