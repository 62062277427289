const initialState = {
  reports: [],
  isLoading: true,
  createReportForm: {
    name: '',
    startDate: new Date(),
    endDate: new Date(),
    categories: [],
    fields: [],
    loader: false,
  },
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GOT_REPORTS':
      return {
        ...state,
        reports: action.res.data.result.records,
      };
    case 'LOADING_REPORTS':
      return {
        ...state,
        isLoading: action.value,
      };
    case 'CHANGE_REPORT_FORM_VALUE':
      return {
        ...state,
        createReportForm: {
          ...state.createReportForm,
          [action.key]: action.value,
        },
      };
    default:
      return state;
  }
};

export default reportsReducer;
