import moment from 'moment';
import { KEYS } from '@utils';

export const WEEKS = {
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednesday',
  Thu: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
  Sun: 'Sunday',
};

export const HOURS = {
  0: '00:00 - 01:00',
  1: '01:00 - 02:00',
  2: '02:00 - 03:00',
  3: '03:00 - 04:00',
  4: '04:00 - 05:00',
  5: '05:00 - 06:00',
  6: '06:00 - 07:00',
  7: '07:00 - 08:00',
  8: '08:00 - 09:00',
  9: '09:00 - 10:00',
  10: '10:00 - 11:00',
  11: '11:00 - 12:00',
  12: '12:00 - 13:00',
  13: '13:00 - 14:00',
  14: '14:00 - 15:00',
  15: '15:00 - 16:00',
  16: '16:00 - 17:00',
  17: '17:00 - 18:00',
  18: '18:00 - 19:00',
  19: '19:00 - 20:00',
  20: '20:00 - 21:00',
  21: '21:00 - 22:00',
  22: '22:00 - 23:00',
  23: '23:00 - 24:00',
};

/*
 * Last 30 days will be picked if website live date is older.
 * It will pick the live date otherwise.
 */
export const getStartTimestamp = (defaultDate = moment().subtract(29, 'day').startOf('day')) => {
  const activeWebsiteLiveDate = JSON.parse(localStorage.getItem(KEYS.ACTIVE_WEBSITE))?.live_date;
  // Set live date as start if live date is younger than last month.
  if (activeWebsiteLiveDate && moment(activeWebsiteLiveDate).clone().isAfter(defaultDate)) {
    return moment(activeWebsiteLiveDate).valueOf();
  }
  return defaultDate.valueOf();
};

/**
 * Device Details
 * @param {object} log - Device logs.
 * @returns {Object} - Returning device details.
 */

const getDeviceDetails = (log) => ({
  device: log.user_agent_device_model || 'Unknown',
  os: log.user_agent_os_family || 'Unknown',
  version: log.user_agent_os_version_string || 'Unknown',
  isPc: log.is_pc,
  scan: 1,
  date: moment(log.log_date).format('DD MMM YYYY'),
});

/*
 * Group logs by the mutual date they have and sum necessary values.
 */
/**
 * Logs By Date
 * @param {Array} logs - Logs by date to be grouped.
 * @param {array} [allDates = []] - Contains dates in all log
 * @returns {Object} - It returns groups data according to log dates.
 */

export const groupLogsByDate = (logs, allDates = []) => {
  // Contains a list of date-values mapping. E.g., "10 March: {customValue1, customValue2}"
  const dateScans = {};
  for (const log of logs) {
    const dateKey = moment(log.log_date).format('DD MMM YYYY');
    if (!dateScans[dateKey]) {
      dateScans[dateKey] = {
        scans: 1,
        actions: log.qr_action_logs,
        devices: [getDeviceDetails(log)],
        log,
      };
      continue;
    }
    dateScans[dateKey].scans++;
    dateScans[dateKey].actions.push(...log.qr_action_logs);
    dateScans[dateKey].devices.push(getDeviceDetails(log));
  }
  // Adding the date and log that are not according to the dates in the total log
  for (const log of allDates) {
    const formatDate = moment(log.log_date).format('DD MMM YYYY');
    if (!dateScans[formatDate]) {
      dateScans[formatDate] = {
        log,
        scans: 0,
      };
    }
  }

  return Object.entries(dateScans)
    .map(([date, value]) => ({
      ...value.log,
      fullDate: date,
      shortDate: moment(date).format('DD MMM'),
      scans: value.scans,
      actions: value.actions,
      devices: value.devices,
    }))
    .sort((a, b) => Date.parse(a.fullDate) - Date.parse(b.fullDate));
};

/**
 * Format Date By Type
 * @param {date} date - Active Date.
 * @param {type} monthType - Month Type.
 * @returns {string} - Returns short date format (example: '15 Dec').
 */

export const formatDateByType = ({ date, monthType = 'DD MMM' }) => {
  let formatterType = monthType;
  // If it is longer than 10, it contains hours in the same day.
  if (date.length > 10) {
    formatterType = 'HH:mm';
  }
  return moment(date).clone().format(formatterType);
};

/*
 * Countly returns unfiltered data for some endpoints, the data might
 * includes previous months and years. Filter it to retrieve necessary buckets.
 * E.g., {2021: {1: {1: vc: bucket}, 2: {1: vc: bucket}}} -> buckets for given period.
 */
/**
 * Filter Custom Period
 * @param {object} rawData - Countly Data.
 * @param {object} period - Active Time Stamp.
 * @returns {object} - Returns the values relative to the frequency bucket.
 */

export const filterCustomPeriod = (rawData, period) => {
  if (!period) {
    return {};
  }

  const frequencyBuckets = {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
  };

  const differenceBetween = moment(period.end).diff(period.start, 'days') + 1;
  // Get start date in Countly format to match with keys. E.g., 1-9-2021
  const originalStart = moment(period.start).format('D-M-YYYY');
  let [dayKey, monthKey, yearKey] = originalStart.split('-');
  for (let _ = 0; _ < differenceBetween; _++) {
    // Countly do not have any value for the selected start month or year.
    // This will be fixed when we implement minimum start date.
    if (!rawData[yearKey]) {
      dayKey = 1;
      monthKey = 1;
      yearKey++;
      continue;
    }
    if (!rawData[yearKey][monthKey]) {
      dayKey = 1;
      monthKey++;
      continue;
    }
    const currentBuckets = rawData[yearKey][monthKey][dayKey]?.vc || {};
    // Add current buckets to the existing frequency bucket.
    Object.entries(currentBuckets).forEach(([key, value]) => (frequencyBuckets[key] += value));
    dayKey++;

    // If the next day is out of month range, proceed to next month.
    if (dayKey > moment(`${yearKey}-${monthKey}`).daysInMonth()) {
      dayKey = 1;
      monthKey++;
    }
    // Proceed to next year if next month is out of year.
    if (!rawData[yearKey][monthKey]) {
      dayKey = 1;
      monthKey = 1;
      yearKey++;
      // Next year does not exist.
      if (!rawData[yearKey]) return frequencyBuckets;
    }
  }
  return frequencyBuckets;
};

/*
 * Convert single date digit to double by split value.
 * E.g., "10-9-2021" -> "10-09-2021".
 */
/**
 * convert Date To Double Digits
 * @param {date} date
 * @param {string} splitBy
 * @returns {string} - splited date (examle: '2021-12-09')
 */

const convertDateToDoubleDigits = ({ date, splitBy }) => {
  return date
    .split(splitBy)
    .map((word) => word.padStart(2, '0'))
    .join(splitBy);
};

export const splitAndJoinDateStr = ({ date, splitBy, joinBy }) => {
  return date
    .split(splitBy)
    .map((word) => word.padStart(2, '0'))
    .join(joinBy);
};

/*
 * Convert single hour:min digit to double by split value.
 * E.g., "10-9-2021 1:30" -> "10-9-2021 01:30".
 */
/**
 /**
 * Convert Hour To Double Digits
 * @param {date} date 
 * @returns {string} - splited hour (examle: '2021-12-13 00:00')
 */

const convertHourToDoubleDigits = (date) => {
  return date
    .split(' ')
    .map((word) => convertDateToDoubleDigits({ date: word, splitBy: ':' }))
    .join(' ');
};

/**
 * Format Countly Date
 * @param {date} date
 * @returns {string} - splited date (examle: '2021-12-09')
 */

export const formatCountlyDate = (date) => {
  let formattedDate = date;
  // Date includes hours.
  if (date.includes(':')) {
    formattedDate = convertHourToDoubleDigits(date);
  }

  return convertDateToDoubleDigits({ date: formattedDate, splitBy: '-' });
};

/*
 * Normalize timezone differences to avoid sliding into the next/previous day.
 */
const removeTimezoneFromTimestamp = (ts) => moment(ts).clone().utcOffset(0, true).unix();
export const removeTimezoneFromTimestampAsMs = (ts) => moment(ts).clone().utcOffset(0, true).valueOf();
/**
 * Format Period Range
 * @param {object} periodRange - active time stamp.
 * @returns {array} - time stamp format returns (example: [1636934400, 1639612799])
 */

export const formatPeriodRange = (periodRange) => {
  if (!periodRange) {
    return '';
  }
  return `[${removeTimezoneFromTimestamp(periodRange.start)}, ${removeTimezoneFromTimestamp(
    periodRange.end,
  )}]`;
};
