import moment from 'moment';
import { getStartTimestamp } from '@time-helpers';

// ** Initial State
const initialState = {
  suggestions: [],
  bookmarks: [],
  query: '',
  smartDevices: [],
  websites: [],
  totalSKU: 0,
  triedSKU: 0,
  activeTimestamps: {
    start: getStartTimestamp(),
    end: moment().endOf('day').valueOf(),
    monthSelected: false,
  },
  visibleDate: 'Last 30 Days',
};

const navbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_SEARCH_QUERY':
      return { ...state, query: action.val };
    case 'GET_BOOKMARKS':
      return {
        ...state,
        suggestions: action.data,
        bookmarks: action.bookmarks,
      };
    case 'GOT_WEBSITES':
      return {
        ...state,
        smartDevices: action.smartDevices || [],
        websites: action.websites || [],
        totalSKU: action.totalSKU || 0,
        triedSKU: action.triedSKU || 0,
      };
    case 'SET_ACTIVE_DATES':
      return {
        ...state,
        activeTimestamps: action.data,
      };
    case 'SET_VISIBLE_DATES':
      return {
        ...state,
        visibleDate: action.data,
      };
    case 'UPDATE_BOOKMARKED': {
      let objectToUpdate;
      // ** find & update object
      state.suggestions.find((item) => {
        if (item.id === action.id) {
          item.isBookmarked = !item.isBookmarked;
          objectToUpdate = item;
        }
      });

      // ** Get index to add or remove bookmark from array
      const bookmarkIndex = state.bookmarks.findIndex((x) => x.id === action.id);
      if (bookmarkIndex === -1) {
        state.bookmarks.push(objectToUpdate);
      } else {
        state.bookmarks.splice(bookmarkIndex, 1);
      }

      return { ...state };
    }
    default:
      return state;
  }
};

export default navbarReducer;
