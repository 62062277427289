const initialState = {
  items: [],
};

const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GOT_FAQS':
      return {
        ...state,
        items: action.res.data.result.response,
      };

    case 'LOAD_FAQS_ERROR':
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default faqReducer;
