const initialState = {
  skinPages: {
    data: {},
    onboardingTotal: 0,
    questionTotal: 0,
    takePhotoTotal: 0,
    resultTotal: 0,
    isLoading: true,
  },
};

const skinReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GOT_SKIN_PAGES_LOG':
      // Object representing event logs might be empty, handle it here.
      if (!Object.keys(action.data).length) {
        return { ...state, skinPages: { ...state.skinPages, isLoading: false } };
      }

      return {
        ...state,
        skinPages: {
          data: action.data,
          onboardingTotal: action.data.OnboardingPage.reduce(
            (prev, session) => prev + session.c,
            0,
          ),
          questionTotal: action.data.QuestionPage.reduce((prev, session) => prev + session.c, 0),
          takePhotoTotal: action.data.TakeAPhotoPage.reduce((prev, session) => prev + session.c, 0),
          resultTotal: action.data.ResultPage.reduce((prev, session) => prev + session.c, 0),
          isLoading: false,
        },
      };
    default:
      return state;
  }
};

export default skinReducer;
