// ** Redux Imports
import { combineReducers } from 'redux';

// ** Reducers Imports
import auth from './auth';
import navbar from './navbar';
import layout from './layout';
import statistic from './statistic';
import faq from './faq';
import reports from './reports';
import products from './products';
import changePassword from './changePassword';
import dataTables from '@src/views/tables/data-tables/store/reducer';
import plugin from './plugin';
import template from './template';
import countly from './countly';
import qr from './qr';
import controlPanel from './controlPanel';
import skin from './skin';
import odooProducts from './odooProducts';

const appReducer = combineReducers({
  auth,
  navbar,
  layout,
  statistic,
  faq,
  reports,
  products,
  dataTables,
  changePassword,
  plugin,
  template,
  countly,
  qr,
  controlPanel,
  skin,
  odooProducts,
});
const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
