import {
  getCountryName,
  getTopPlatformVersions,
  groupVersionsByPlatform,
  formatModelsLog,
  getTopValues,
  getTopValuesWithoutTotal,
  getFormattedDailyData,
} from '@utils';
import { filterCustomPeriod, formatCountlyDate } from '@time-helpers';

const initialState = {
  totalUser: {
    data: null,
    isLoading: true,
  },
  totalUserPrev: {
    data: null,
    isLoading: true,
  },
  modelsClickLog: {
    data: [],
    isLoading: true,
  },
  modelsUrlLog: {
    data: [],
    isLoading: true,
  },
  productLog: {
    data: [],
    totalLog: 0,
    isLoading: true,
  },
  productLogPrev: {
    data: [],
    totalLog: 0,
    isLoading: true,
  },
  addToCartLog: {
    data: [],
    totalLog: 0,
    isLoading: true,
  },
  addToCartLogPrev: {
    data: [],
    totalLog: 0,
    isLoading: true,
  },
  goToProductLog: {
    data: [],
    totalLog: 0,
    isLoading: true,
  },
  goToProductLogPrev: {
    data: [],
    isLoading: true,
    totalLog: 0,
  },
  visitorLoyalty: {
    data: {},
    isLoading: true,
  },
  viewsPerSession: {
    data: {},
    isLoading: true,
  },
  totalSession: {
    data: [],
    totalSessionCount: null,
    totalDurationCount: null,
    newSessionCount: null,
    isLoading: true,
  },
  totalSessionPrev: {
    data: [],
    totalSessionCount: null,
    totalDurationCount: null,
    newSessionCount: null,
    isLoading: true,
  },
  sessionDurationLog: {
    data: [],
    isLoading: true,
  },
  isLoading: true,
  devices: {
    data: [],
    isLoading: true,
  },
  deviceTypes: {
    data: [],
    isLoading: true,
  },
  languages: {
    data: [],
    isLoading: true,
  },
  deviceResolutions: {
    data: [],
    isLoading: true,
  },
  topValues: {
    platforms: [],
    resolutions: [],
    isLoading: true,
  },
  platforms: {
    data: [],
    isLoading: true,
  },
  platformVersions: {
    data: [],
    topVersions: [],
    isLoading: true,
  },
  countriesData: {
    totalUsers: [],
    totalSessions: [],
    newUsers: [],
    countries: [],
    isLoading: true,
  },
  timesOfDay: {
    data: [],
    isLoading: true,
  },
  slippingAway: {
    data: [],
    isLoading: true,
  },
  skinLandingPage: {
    data: [],
    totalLog: {},
    isLoading: true,
  },
  skinLandingPagePrev: {
    data: [],
    totalLog: {},
    isLoading: true,
  },
  skinOnboardingPage: {
    data: [],
    totalLog: {},
    isLoading: true,
  },
  skinOnboardingPagePrev: {
    data: [],
    totalLog: {},
    isLoading: true,
  },
  skinResultPage: {
    data: [],
    totalLog: {},
    isLoading: true,
  },
  skinResultPagePrev: {
    data: [],
    totalLog: {},
    isLoading: true,
  },
  skinEmailReport: {
    data: [],
    totalLog: {},
    isLoading: true,
  },
  skinEmailReportPrev: {
    data: [],
    totalLog: {},
    isLoading: true,
  },
  skinQuestionPage: {
    totalLog: {},
    isLoading: true,
  },
  skinAnalysisPage: {
    totalLog: {},
    data: [],
    isLoading: true,
  },
  top5AddToCart: {
    data: [],
    isLoading: true,
  },
  browsers: {
    data: [],
    isLoading: true,
  },
  skinFirstData: {
    data: [],
    isLoading: true,
  },
  skinFirstInstructionPage: {
    totalUser: 0,
    isLoading: true,
  },
  skinFirstSelfieSelectionPage: { totalUser: {}, isLoading: true },
  skinFirstResultPage: { totalUser: {}, isSkinFirstTemplate: false, isLoading: true },
  skinFirstAddToCart: { totalUser: {}, isLoading: true },
  skinFirstGoToProduct: { totalUser: {}, isLoading: true },
  skinEmailLogs: {
    items: [],
    totalItems: 0,
    itemCount: 0,
    currentPage: 1,
    totalPages: 1,
    isLoading: true,
    isPaginationLoading: false,
  },
};

const countlyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GOT_TOTAL_USER':
      return {
        ...state,
        totalUser: {
          data: action.data.u,
          isLoading: action.isLoading,
        },
      };
    case 'GOT_TOTAL_USER_PREV':
      return {
        ...state,
        totalUserPrev: {
          data: action.data.u,
          isLoading: false,
        },
      };
    case 'GOT_VISITOR_LOYALTY':
      return {
        ...state,
        visitorLoyalty: {
          data: action.data,
          isLoading: false,
        },
      };
    case 'GOT_VIEWS_PER_SESSION':
      return {
        ...state,
        viewsPerSession: {
          data: filterCustomPeriod(action.data, action.period),
          isLoading: false,
        },
      };
    case 'GOT_TOTAL_SESSION':
      return {
        ...state,
        totalSession: {
          data: action.data.map((date) => ({ ...date, _id: formatCountlyDate(date._id) })),
          totalSessionCount: action.data.reduce((prev, session) => prev + session.t, 0),
          uniqueSessionCount: action.data.reduce((prev, session) => prev + session.u, 0),
          totalDurationCount: action.data.reduce((prev, session) => prev + session.d, 0),
          newSessionCount: action.data.reduce((prev, session) => prev + session.n, 0),
          isLoading: action.isLoading,
        },
      };
    case 'GOT_TOTAL_SESSION_PREV':
      return {
        ...state,
        totalSessionPrev: {
          data: action.data.map((date) => ({ ...date, _id: formatCountlyDate(date._id) })),
          totalSessionCount: action.data.reduce((prev, session) => prev + session.t, 0),
          uniqueSessionCount: action.data.reduce((prev, session) => prev + session.u, 0),
          totalDurationCount: action.data.reduce((prev, session) => prev + session.d, 0),
          newSessionCount: action.data.reduce((prev, session) => prev + session.n, 0),
          isLoading: false,
        },
      };
    case 'COUNTLY_LOADING':
      return {
        ...state,
        isLoading: action.data,
      };
    case 'GOT_MODELS_URL_LOG':
      return {
        ...state,
        modelsUrlLog: {
          data: formatModelsLog(action.data),
          isLoading: false,
        },
      };
    case 'GOT_PRODUCT_LOG':
      return {
        ...state,
        productLog: {
          data: action.data.map((date) => ({ ...date, _id: formatCountlyDate(date._id) })),
          totalLog: getTopValues({ data: action.data, type: 'c' }),
          isLoading: action.isLoading,
        },
      };
    case 'GOT_PRODUCT_LOG_PREV':
      return {
        ...state,
        productLogPrev: {
          data: action.data.map((date) => ({
            ...date,
            _id: formatCountlyDate(date._id),
          })),
          totalLog: getTopValues({ data: action.data, type: 'c' }),
          isLoading: false,
        },
      };
    case 'GOT_ADD_TO_CART_LOG':
      return {
        ...state,
        addToCartLog: {
          data: action.data.map((date) => ({ ...date, _id: formatCountlyDate(date._id) })),
          totalLog: getTopValues({ data: action.data, type: 'c' }),
          isLoading: action.isLoading,
        },
      };
    case 'GOT_ADD_TO_CART_LOG_PREV':
      return {
        ...state,
        addToCartLogPrev: {
          data: action.data.map((date) => ({ ...date, _id: formatCountlyDate(date._id) })),
          totalLog: getTopValues({ data: action.data, type: 'c' }),
          isLoading: false,
        },
      };
    case 'GOT_GO_TO_PRODUCT_LOG':
      return {
        ...state,
        goToProductLog: {
          data: action.data.map((date) => ({ ...date, _id: formatCountlyDate(date._id) })),
          totalLog: getTopValues({ data: action.data, type: 'c' }),
          isLoading: action.isLoading,
        },
      };
    case 'GOT_GO_TO_PRODUCT_LOG_PREV':
      return {
        ...state,
        goToProductLogPrev: {
          data: action.data.map((date) => ({ ...date, _id: formatCountlyDate(date._id) })),
          totalLog: getTopValues({ data: action.data, type: 'c' }),
          isLoading: false,
        },
      };
    case 'GOT_MODELS_CLICK_LOG':
      return {
        ...state,
        modelsClickLog: {
          data: action.data.map((log) => ({ ...log, _id: formatCountlyDate(log._id) })),
          isLoading: false,
        },
      };
    case 'GOT_SESSION_DURATION_LOG':
      return {
        ...state,
        sessionDurationLog: {
          data: action.data.sort((a, b) => a.ds - b.ds),
          isLoading: false,
        },
      };
    case 'GOT_DEVICES':
      return {
        ...state,
        devices: {
          data: action.data,
          isLoading: action.isLoading,
        },
      };
    case 'GOT_DEVICE_TYPES':
      return {
        ...state,
        deviceTypes: {
          data: action.data,
          isLoading: false,
        },
      };
    case 'GOT_DEVICE_RESOLUTIONS':
      return {
        ...state,
        deviceResolutions: {
          data: action.data,
          isLoading: false,
        },
      };
    case 'GOT_LANGUAGES':
      return {
        ...state,
        languages: {
          data: action.data,
          isLoading: false,
        },
      };
    case 'GOT_TOP_VALUES':
      return {
        ...state,
        topValues: {
          platforms: action.data?.platforms || [],
          resolutions: action.data?.resolutions || [],
          isLoading: action.isLoading,
        },
      };
    case 'GOT_PLATFORMS':
      return {
        ...state,
        platforms: {
          data: action.data
            .sort((a, b) => b.t - a.t)
            .map((platform) => {
              if (platform._id === 'Chromium OS') platform._id = 'Chromium';
              return platform;
            }),
          isLoading: false,
        },
      };
    case 'GOT_PLATFORM_VERSIONS':
      return {
        ...state,
        platformVersions: {
          data: groupVersionsByPlatform(action.data),
          topVersions: getTopPlatformVersions(action.data),
          isLoading: false,
        },
      };
    case 'GOT_COUNTRIES_DATA':
      return {
        ...state,
        countriesData: {
          totalUsers: action.data.map((country) => ({ id: country._id, value: country.u })),
          totalSessions: action.data.map((country) => ({ id: country._id, value: country.t })),
          newUsers: action.data.map((country) => ({ id: country._id, value: country.n })),
          countries: action.data
            .filter((country) => country._id !== 'Unknown')
            .map((country) => ({
              country: getCountryName(country._id),
              total_sessions: country.t,
              total_visitors: country.u,
              new_visitors: country.n,
            })),
          isLoading: false,
        },
      };
    case 'GOT_TIMES_OF_DAY':
      return {
        ...state,
        timesOfDay: {
          data: action.data,
          isLoading: false,
        },
      };
    case 'GOT_SLIPPING_AWAY':
      return {
        ...state,
        slippingAway: {
          data: action.data,
          isLoading: false,
        },
      };
    case 'GOT_SKIN_LANDING_PAGE': {
      const dailyData = getFormattedDailyData(action.data?.data?.daily || {});
      return {
        ...state,
        skinLandingPage: {
          data: dailyData,
          totalLog: {
            total: action.data?.totals?.u || 0,
            impression: action.data?.totals?.t || 0,
            values: getTopValuesWithoutTotal({ data: dailyData, type: 'c' }),
          },
          isLoading: action.isLoading,
        },
      };
    }
    case 'GOT_SKIN_LANDING_PAGE_PREV': {
      const dailyData = getFormattedDailyData(action.data?.data?.daily || {});
      return {
        ...state,
        skinLandingPagePrev: {
          data: dailyData,
          totalLog: {
            total: action.data?.totals?.u || 0,
            impression: action.data?.totals?.t || 0,
            values: getTopValuesWithoutTotal({ data: dailyData, type: 'c' }),
          },
          isLoading: action.isLoading,
        },
      };
    }
    case 'GOT_SKIN_ONBOARDING_PAGE':
      return {
        ...state,
        skinOnboardingPage: {
          data: action.data.map((date) => ({ ...date, _id: formatCountlyDate(date._id) })),
          totalLog: getTopValues({ data: action.data, type: 'c' }),
          isLoading: action.isLoading,
        },
      };
    case 'GOT_SKIN_ONBOARDING_PAGE_PREV':
      return {
        ...state,
        skinOnboardingPagePrev: {
          data: action.data.map((date) => ({ ...date, _id: formatCountlyDate(date._id) })),
          totalLog: getTopValues({ data: action.data, type: 'c' }),
          isLoading: false,
        },
      };
    case 'GOT_SKIN_RESULT_PAGE': {
      const dailyData = getFormattedDailyData(action.data?.data?.daily || {});
      return {
        ...state,
        skinResultPage: {
          data: dailyData,
          totalLog: {
            total: action.data?.totals?.u || 0,
            impression: action.data?.totals?.t || 0,
            values: getTopValuesWithoutTotal({ data: dailyData, type: 'c' }),
          },
          isLoading: action.isLoading,
        },
      };
    }
    case 'GOT_SKIN_RESULT_PAGE_PREV': {
      const dailyData = getFormattedDailyData(action.data?.data?.daily || {});
      return {
        ...state,
        skinResultPagePrev: {
          data: dailyData,
          totalLog: {
            total: action.data?.totals?.u || 0,
            impression: action.data?.totals?.t || 0,
            values: getTopValuesWithoutTotal({ data: dailyData, type: 'c' }),
          },
          isLoading: action.isLoading,
        },
      };
    }
    case 'GOT_SKIN_FIRST_INSTRUCTION_PAGE': {
      return {
        ...state,
        skinFirstInstructionPage: {
          totalUser: action.data?.totals?.u || 0,
          isLoading: action.isLoading,
        },
      };
    }
    case 'GOT_SKIN_FIRST_SELFIE_SELECTION_PAGE': {
      return {
        ...state,
        skinFirstSelfieSelectionPage: {
          totalUser: {
            skipSelfie: action.data?.snapshots?.u?.skip_selfie || 0,
            takeSelfie: action.data?.snapshots?.u?.take_selfie || 0,
          },
          isLoading: action.isLoading,
        },
      };
    }
    case 'GOT_SKIN_FIRST_RESULT_PAGE': {
      return {
        ...state,
        skinFirstResultPage: {
          totalUser: {
            skipSelfie: action.data?.snapshots?.u?.skip_selfie || 0,
            takeSelfie: action.data?.snapshots?.u?.take_selfie || 0,
          },
          isLoading: action.isLoading,
          isSkinFirstTemplate: Boolean(
            action.data.segments?.take_selfie?.t || action.data.segments?.skip_selfie?.t,
          ),
        },
      };
    }
    case 'GOT_SKIN_FIRST_ADD_TO_CART': {
      return {
        ...state,
        skinFirstAddToCart: {
          totalUser: {
            skipSelfie: action.data?.snapshots?.u?.skip_selfie || 0,
            takeSelfie: action.data?.snapshots?.u?.take_selfie || 0,
          },
          isLoading: action.isLoading,
        },
      };
    }
    case 'GOT_SKIN_FIRST_GO_TO_PRODUCT': {
      return {
        ...state,
        skinFirstGoToProduct: {
          totalUser: {
            skipSelfie: action.data?.snapshots?.u?.skip_selfie || 0,
            takeSelfie: action.data?.snapshots?.u?.take_selfie || 0,
          },
          isLoading: action.isLoading,
        },
      };
    }
    case 'GOT_SKIN_EMAIL_REPORT':
      return {
        ...state,
        skinEmailReport: {
          data: action.data.map((date) => ({ ...date, _id: formatCountlyDate(date._id) })),
          totalLog: getTopValues({ data: action.data, type: 'c' }),
          isLoading: action.isLoading,
        },
      };
    case 'GOT_SKIN_EMAIL_REPORT_PREV':
      return {
        ...state,
        skinEmailReportPrev: {
          data: action.data.map((date) => ({ ...date, _id: formatCountlyDate(date._id) })),
          totalLog: getTopValues({ data: action.data, type: 'c' }),
          isLoading: false,
        },
      };
    case 'GOT_SKIN_QUESTION_PAGE': {
      const dailyData = getFormattedDailyData(action.data?.data?.daily || {});
      return {
        ...state,
        skinQuestionPage: {
          totalLog: {
            total: action.data?.totals?.u || 0,
            impression: action.data?.totals?.t || 0,
            values: getTopValuesWithoutTotal({ data: dailyData, type: 'c' }),
          },
          isLoading: action.isLoading,
        },
      };
    }
    case 'GOT_SKIN_ANALYZE_PAGE': {
      const dailyData = getFormattedDailyData(action.data?.data?.daily || {});
      return {
        ...state,
        skinAnalysisPage: {
          data: dailyData,
          totalLog: {
            total: action.data?.totals?.u || 0,
            impression: action.data?.totals?.t || 0,
            values: getTopValuesWithoutTotal({ data: dailyData, type: 'c' }),
          },
          isLoading: action.isLoading,
        },
      };
    }
    case 'GOT_TOP_5_ADD_TO_CART':
      return {
        ...state,
        top5AddToCart: {
          data: action.data,
          isLoading: action.isLoading,
        },
      };
    case 'GOT_BROWSERS':
      return {
        ...state,
        browsers: {
          data: action.data,
          isLoading: action.isLoading,
        },
      };
    case 'GOT_SKIN_FIRST_DATA': {
      return {
        ...state,
        skinFirstData: {
          data: action.data,
          isLoading: action.isLoading,
        },
      };
    }
    case 'GOT_SKIN_EMAIL_LOGS': {
      return {
        ...state,
        skinEmailLogs: {
          items: action.data.items,
          itemCount: action.data.itemCount,
          currentPage: action.data.currentPage,
          totalPages: action.data.totalPages,
          totalItems: action.data.totalItems,
          isLoading: action.isLoading,
          isPaginationLoading: action.isPaginationLoading || false,
        },
      };
    }
    case 'GOT_SKIN_EMAIL_LOGS_PAGINATION': {
      const populatedItems = [...state.skinEmailLogs.items, ...action.data.items];
      return {
        ...state,
        skinEmailLogs: {
          ...state.skinEmailLogs,
          items: populatedItems,
          itemCount: action.data.itemCount || state.skinEmailLogs.itemCount,
          currentPage: action.data.currentPage || state.skinEmailLogs.currentPage,
          totalPages: action.data.totalPages || state.skinEmailLogs.totalPages,
          totalItems: action.data.totalItems || state.skinEmailLogs.totalItems,
          isLoading: false,
          isPaginationLoading: false,
        },
      };
    }
    case 'GOT_SKIN_EMAIL_LOGS_PAGINATION_LOADING': {
      return {
        ...state,
        skinEmailLogs: {
          ...state.skinEmailLogs,
          isPaginationLoading: action.isPaginationLoading,
        },
      };
    }
    default:
      return state;
  }
};

export default countlyReducer;
