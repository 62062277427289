import { getQrLogDetails, getUniqueQrLogs } from '@qr-helpers';

const initialState = {
  qrList: {
    data: [],
    // Contains the log details of requested part of list.
    details: [],
    isDetailsLoading: true,
    isListLoading: true,
  },
  qrLogs: {
    uuid: null,
    logs: {
      total: [],
      unique: [],
      returning: [],
    },
    isLoading: true,
  },
  createdUUID: '',
  loader: false,
  error: false,
  qrDetails: [],
  editStatus: false,
};

const qrReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GOT_QR_LIST':
      return {
        ...state,
        qrList: {
          ...state.qrList,
          data: action.data.filter((qr) => qr.user_deleted === false),
          isListLoading: false,
        },
      };
    case 'GOT_QR_LIST_DETAILS':
      return {
        ...state,
        qrList: {
          ...state.qrList,
          details: action.data.map((qr) => ({
            uuid: qr.uuid,
            logs: {
              total: qr.logs,
              unique: getUniqueQrLogs(qr.logs),
            },
          })),
          isDetailsLoading: false,
        },
      };
    case 'QR_LIST_DETAILS_LOADING':
      return {
        ...state,
        qrList: {
          ...state.qrList,
          isDetailsLoading: action.isLoading,
        },
      };
    case 'GOT_SINGLE_QR_LOGS': {
      if (!action.data.length) {
        return {
          ...state,
          qrLogs: {
            ...state.qrLogs,
            isLoading: false,
          },
        };
      }
      const { uuid, logs } = getQrLogDetails(action.data[0]);
      return {
        ...state,
        qrLogs: {
          uuid,
          logs,
          title: action.data[0].qr.title,
          image_url: action.data[0].qr.image_url,
          isLoading: false,
        },
      };
    }
    case 'SINGLE_QR_LOADING':
      return {
        ...state,
        qrLogs: {
          ...state.qrLogs,
          isLoading: action.isLoading,
        },
      };
    case 'QR_LOADER':
      return {
        ...state,
        loader: action.value,
      };
    case 'CREATED_UUID':
      return {
        ...state,
        createdUUID: action.value,
        loader: false,
      };
    case 'GOT_QR_DETAILS':
      return {
        ...state,
        qrDetails: action.value,
        loader: false,
      };
    case 'EDIT_STATUS':
      return {
        ...state,
        editStatus: action.value,
        loader: false,
      };
    case 'RESET_QR_PARAMS':
      return {
        ...state,
        editStatus: false,
        loader: false,
        createdUUID: '',
        error: false,
        qrDetails: [],
      };
    default:
      return state;
  }
};

export default qrReducer;
