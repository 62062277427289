const initialState = {
  userData: {},
};

const changePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_PASSWORD':
      return {
        ...state,
        userData: action.data,
      };
    default:
      return state;
  }
};

export default changePasswordReducer;
