import countlyOsMapping from '@utility/constants/countly-libraries/countly.device.osmapping';
import ISO_COUNTRIES from '@utility/constants/countly-libraries/country_codes';
import { HOURS, WEEKS, splitAndJoinDateStr } from '@time-helpers';

const ChartTheme = {
  CROSSLINE_DARK: '#B9B9C3',
  CROSSLINE_BRIGHT: '#4B4B4B',
  GRID_DARK: '#676D7D',
  GRID_BRIGHT: '#EBE9F1',
};

const sumCountsByType = (data, type) => data.reduce((prev, current) => prev + current[type], 0);

export const trackBgColor = '#e9ecef';
export const ProductTitle = {
  BRAND: 0,
  CATEGORY: 1,
};

export const isNotWeb = () => window.innerWidth <= 998;
export const isSmallTablet = () => window.innerWidth <= 768;
export const isMobile = () => window.innerWidth <= 576;
export const isNarrowMobile = () => window.innerWidth <= 420;
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
/**
 * k Formatter
 * @param {number} num
 * @returns {number} - returns number format k
 */

export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num);

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData');
export const getUserData = () => JSON.parse(localStorage.getItem('userData'));

/**
 * Calculate Percentage
 * @param {number} unit - Unit Number
 * @param {number} total - Total Number
 * @param {number} digit - Digit after comma
 * @returns {string} - percentage of params
 */

export const calculatePercentage = ({ unit, total, digit = 2 }) => {
  if (!unit || !total) return 0;
  return ((unit / total) * 100).toFixed(digit);
};

// ** Checks if an object is empty (returns boolean)
export const KEYS = {
  ACTIVE_WEBSITE: 'pulpo_active_website',
  storageTokenKeyName: 'pulpo_session',
  storageRefreshTokenKeyName: 'pulpo_refresh_token',
};

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '');

export const ChartColors = {
  TEXT: '#919eab',
  SESSION_TYPES: ['#8925FA', '#0D6EFD', '#D63384'],
  // Stacked bars in PlatformsChart starts at #1 index, add null first to match colors.
  PLATFORM_TYPES: [
    null,
    '#8925FA',
    '#0D6EFD',
    '#00CFE8',
    '#D63384',
    '#EA5455',
    '#FF9F43',
    '#28C76F',
  ],
  DEVICES: ['#FF9F43', '#EA5455', '#0D6EFD', '#D63384', '#8925FA'],
  TOP_STATISTICS: ['#8925FA', '#9AFFFF', '#5ABDF7', '#3047EC', '#D0A8FD', '#370F64'],
  INTERACTIONS: ['#0D6EFD', '#D63384'],
  QR_STATISTICS: ['#8925FA', '#D63384'],
  PIE_CHART_COLORS: ['#8925FA', '#8A8D8F'],
};

export const hasDefaultWebsite = () => {
  return localStorage.getItem(KEYS.ACTIVE_WEBSITE) !== null;
};

/**
 * Clean Array Data For UI
 * @param {array} data
 * @returns {array} - It checks whether the returned response is empty and returns an array.
 */

export const cleanArrayDataForUI = (data) => {
  if (!Array.isArray(data)) return [];
  return data.filter((item) => item);
};

/**
 * clean Object Data For UI
 * @param {Object} data
 * @returns {object} - It checks whether the returned response is empty and returns an object.
 */

export const cleanObjectDataForUI = (data) => {
  if (Object.keys(data).length === 0 && data.constructor === Object) return {};
  return data;
};

/**
 * Check if GDPR text is valid.
 * @param {string} htmlText
 * @returns {boolean} - Whether has valid GDPR text or not.
 */
export const hasGDPRText = (htmlText) => {
  const emptyGdprText = ['<p><br/></p>', '<p><br></p>'];
  const textWithoutSpaces = htmlText.replace(/\s+/g, '');
  return htmlText && !emptyGdprText.includes(textWithoutSpaces);
};

export const getActiveWebsite = () => {
  const activeWebsite = localStorage.getItem(KEYS.ACTIVE_WEBSITE);
  if (!activeWebsite) return 0;
  return JSON.parse(activeWebsite);
};

export const getActiveUser = () => {
  const activeUser = localStorage.getItem(KEYS.storageTokenKeyName);
  if (!activeUser) return;
  return JSON.parse(activeUser);
};

/**
 * Humanize Number
 * @param {number} num
 * @returns {string} - return number format (22.88K)
 */

export const humanizeNumber = (num) => {
  if (num < 1000) {
    return num;
  }
  const si = [
    { v: 1e3, s: 'K' },
    { v: 1e6, s: 'M' },
    { v: 1e9, s: 'B' },
    { v: 1e12, s: 'T' },
    { v: 1e15, s: 'P' },
    { v: 1e18, s: 'E' },
  ];
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].v) {
      break;
    }
  }
  return (num / si[i].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].s;
};

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 * @returns {String} - According to authentication, path returns
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === 'admin') return '/';
  if (userRole === 'client') return '/access-control';
  return '/login';
};

export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed', // for input hover border-color
  },
});

/**
 * Truncate Text
 * @param {string} text
 * @param {number} charSiz
 * @returns {string} - Returns the value to be displayed by checking the text length.
 */

export const truncateText = (text, charSize) => {
  return text.length > charSize ? `${text.substring(0, charSize)}...` : text;
};

export const getBrandOrCategoryName = ({ productName, titleIndex }) => {
  if (productName?.includes('/')) {
    return productName.split('/')[titleIndex];
  }

  return '-';
};

/**
 * Chart Theme
 * @param {string} themeType
 * @returns {object} returns chart theme Type
 */

export const getChartTheme = (themeType) => ({
  axis: {
    ticks: {
      text: {
        fill: ChartColors.TEXT,
      },
    },
  },
  grid: {
    line: {
      stroke: themeType === 'dark' ? ChartTheme.CROSSLINE_DARK : ChartTheme.CROSSLINE_BRIGHT,
      strokeWidth: 0.5,
    },
  },
  crosshair: {
    line: {
      stroke: themeType === 'dark' ? ChartTheme.GRID_DARK : ChartTheme.GRID_BRIGHT,
      strokeWidth: 0.5,
    },
  },
  tooltip: {
    container: {
      backgroundColor: 'unset',
      boxShadow: 'unset',
      maxWidth: '230px',
      fontSize: '12px',
    },
  },
});

/*
 * react-table cannot sort strings that are consisted of different digits.
 * E.g., '1', '10', '2'. Ref: https://stackoverflow.com/a/38641281/12579543
 */
export const sortDifferentDigitString = (a, b, sortBy = 'desc') => {
  if (!a || !b) return;

  let baseNumber = a,
    comparedNumber = b;
  if (sortBy === 'asc') {
    baseNumber = b;
    comparedNumber = a;
  }

  return baseNumber.localeCompare(comparedNumber, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
};

// Converts given "word" to "Word".
export const capitalizeFirstLetter = (word) => word.charAt(0).toUpperCase() + word.slice(1);

/**
 * Top Values
 * @param {array} data
 * @param {number} limit
 * @param {string} type
 * @param {boolean} shouldIncludeRest
 * @returns {object} - Returns the objects with the highest value.
 */

export const getTopValues = ({ data, limit = 3, type = 'u', shouldIncludeRest = false }) => {
  const filteredData = data.filter(
    (value) =>
      calculatePercentage({
        unit: value[type],
        total: sumCountsByType(data, type),
      }) >= 0.01,
  );
  // Sort them by higher values.
  const sortedValues = filteredData.sort((a, b) => b[type] - a[type]);
  // Get the first n item.
  const topValues = sortedValues.slice(0, limit);

  if (filteredData.length > limit && shouldIncludeRest) {
    const othersData = sortedValues.slice(limit);
    topValues.push({
      _id: 'Others',
      [type]: sumCountsByType(othersData, type),
    });
  }
  return {
    values: topValues,
    total: sumCountsByType(filteredData, type),
  };
};

export const getTopValuesWithoutTotal = ({
  data,
  limit = 3,
  type = 'u',
  shouldIncludeRest = false,
}) => {
  const filteredData = data.filter(
    (value) =>
      calculatePercentage({
        unit: value[type],
        total: sumCountsByType(data, type),
      }) >= 0.01,
  );
  // Sort them by higher values.
  const sortedValues = filteredData.sort((a, b) => b[type] - a[type]);
  // Get the first n item.
  const topValues = sortedValues.slice(0, limit);

  if (filteredData.length > limit && shouldIncludeRest) {
    const othersData = sortedValues.slice(limit);
    topValues.push({
      _id: 'Others',
      [type]: sumCountsByType(othersData, type),
    });
  }
  return topValues;
};

/**
 * Format Countly Platforms
 * @param {string} rawPlatform
 * @returns {object} returns countly platforms info (['iOS', '15.1'])
 */

const formatCountlyPlatforms = (rawPlatform) => {
  // Split platform data by first number and get the string. E.g., i14:4:1 -> i
  const platformShorthand = rawPlatform.split(/(\d+)/)[0];
  // Remove found string, format the version colons. E.g., i14:4:1 -> 14.4.1
  const platformDetail = rawPlatform.slice(platformShorthand.length).replace(/:/g, '.');
  const platformName = Object.values(countlyOsMapping).find(
    (os) => os.short === platformShorthand,
  )?.name;
  return [platformName, platformDetail];
};

/*
 * Formats and group os versions by Countly mapping.
 * It returns Records consisting of platform name and details.
 * E.g., {"iOS": [{name, version, ...}, {name, version, ...}]}
 */
/**
 * Group Versions By Platform
 * @param {array} versionsData
 * @returns {object} retorns platforms({Android: [], Linux: [])
 */

export const groupVersionsByPlatform = (versionsData) => {
  const platforms = {};
  for (const version of versionsData) {
    const [platformName, platformDetail] = formatCountlyPlatforms(version._id);
    // Unknown version type.
    if (!platformName) {
      continue;
    }

    if (!platforms[platformName]) {
      platforms[platformName] = [];
    }
    platforms[platformName].push({
      name: platformName,
      version: platformDetail,
      total_sessions: version.t,
      total_users: version.u,
      new_users: version.n,
    });
  }
  return platforms;
};

/**
 * Top Platform Versions
 * @param {array} data
 * @returns {object} - Returns platform versions and percentages ({name: 'iOS 15.1', percent: '12.23'})
 */

export const getTopPlatformVersions = (data) => {
  // Sort them by higher values and clear unknown types.
  const validVersions = data.filter((version) => version).sort((a, b) => b.t - a.t);
  const versionsTotal = sumCountsByType(validVersions, 't');

  return validVersions.slice(0, 3).map((version) => {
    const [name, detail] = formatCountlyPlatforms(version._id);
    return {
      name: `${name} ${detail}`,
      percent: calculatePercentage({
        unit: version.t,
        total: versionsTotal,
      }),
    };
  });
};

/**
 * Top Tried Data
 * @param {array} data
 * @returns {array} - Returns the objects with the highest value.
 */

export const getTopTriedData = (data) => {
  const validVersions = data.filter((version) => version).sort((a, b) => b.t - a.t);
  // For shorter representation, these stats are abbreviated as; t: total, n: new, u: unique
  const versionsTotal = sumCountsByType(validVersions, 't');

  return validVersions.slice(0, 3).map((version) => {
    return {
      value: version.t,
      name: version._id,
      percent: calculatePercentage({
        unit: version.t,
        total: versionsTotal,
      }),
    };
  });
};

/**
 * Check If Local Log
 * @param {string} logName
 * @returns {boolean} - It checks according to local address.
 */

const checkIfLocalLog = (logName) => {
  return logName.includes('localhost') || logName.includes('127:0:0:1');
};

/**
 * Format Models Log
 * @param {array} logs
 * @returns {array} - Returns logs of models used in live.
 */

export const formatModelsLog = (logs) => {
  return logs
    .filter((log) => !checkIfLocalLog(log._id))
    .map((log) => {
      return {
        count: log.c,
        duration: log.dur,
        // Format "https://domain:extension:com/folder/model_6:png" for src.
        url: `${'https:'}${log._id.split('https:')[1].replace(/:/g, '.')}`,
        // Get last split value and change ":" to '.'.
        imageName: log._id.split('/').pop().replace(/:/g, '.'),
      };
    });
};

/**
 * Filter Tick Values
 * @param {array} tickValues
 * @param {number} maxTickCount
 * @returns {array} - Returns the days to be displayed on the chart.
 */

export const filterTickValues = ({ tickValues, maxTickCount = 8 }) => {
  const divisorNumber = Math.ceil(tickValues.length / maxTickCount);
  return tickValues.filter((_, index) => index % divisorNumber === 0);
};

/**
 * Country Name
 * @param {string} countryCode - ISO Code Country
 * @returns {string} - returns country name
 */

export const getCountryName = (countryCode) => {
  if (Object.prototype.hasOwnProperty.call(ISO_COUNTRIES, countryCode)) {
    return ISO_COUNTRIES[countryCode];
  }
  return countryCode;
};

export const DeviceSessionTypes = {
  TOTAL: 't',
  NEW: 'n',
};

// ** Converts table to CSV
/**
 * Convert Array Of Objects To CSV
 * @param {array} array
 * @returns {string} - Returns the data in the table
 */

function convertArrayOfObjectsToCSV(array) {
  if (array.length === 0) return;
  let result;
  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = Object.keys(array[0]);

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      if (typeof item[key] === 'string' && item[key].includes(',')) {
        item[key] = item[key].replace(/,/g, '');
      }

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

/**
 * Converts an array of objects into a CSV string.
 *
 * This function takes an array of objects, where each object represents a row
 * and the keys of the objects represent the columns. It handles complex data
 * types such as arrays and properly escapes special characters (commas, newlines,
 * and double quotes) to ensure compatibility with CSV format standards.
 *
 * @param {Array<Object>} array - The array of objects to be converted to CSV format.
 * @returns {string|null} - Returns a CSV string representing the data in the table, or null if the input array is empty.
 *
 * @example
 * const data = [
 *   { name: 'John Doe', emails: ['john@example.com', 'doe@example.com'], age: 30 },
 *   { name: 'Jane Smith', emails: ['jane@example.com'], age: 25 },
 * ];
 * const csv = convertArrayOfObjectsToCSV(data);
 * console.log(csv);
 * // Output:
 * // name,emails,age
 * // John Doe,"john@example.com, doe@example.com",30
 * // Jane Smith,jane@example.com,25
 */
function convertArrayOfObjectsToCSVFields(array) {
  if (array.length === 0) return null;

  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = Object.keys(array[0]);
  let result = '';

  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    const row = keys.map((key) => {
      let value = item[key];

      // Handle array fields by joining elements into a single string
      if (Array.isArray(value)) {
        value = value.join(', ');
      }

      // Escape double quotes by doubling them and wrap values in double quotes if they contain commas, double quotes, or newlines
      if (typeof value === 'string') {
        value = value.replace(/"/g, '""');
        if (value.includes(columnDelimiter) || value.includes('\n') || value.includes('"')) {
          value = `"${value}"`;
        }
      }

      return value;
    });

    result += row.join(columnDelimiter);
    result += lineDelimiter;
  });

  return result;
}

export function downloadCSVWithArrayFields(array) {
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSVFields(array);
  if (!csv) return;

  const filename = 'pulpoar.csv';

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
}

// ** Downloads CSV
/**
 * Download CSV
 * @param {array} array
 */

export function downloadCSV(array) {
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(array);
  if (!csv) return;

  const filename = 'pulpoar.csv';

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
}

/**
 * Filtered Table Data
 * @param {string} name - Search Box Input
 * @param {string} result - base64 Excel Data
 */

export const ExcelConverter = (name, result) => {
  const str = result;
  const contentType = 'application/vnd.ms-excel';

  const byteCharacters = atob(str);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], {
    type: contentType,
  });
  const objectURL = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');

  anchor.href = objectURL;
  anchor.download = name;
  anchor.click();

  URL.revokeObjectURL(objectURL);
};

/**
 * Filtered Table Data
 * @param {string} inputValue - Search Box Input
 * @param {array} tableData - Table Data
 * @param {string} searchKey - Search Column
 * @returns {array} - returns matching items in search
 */

export const getFilteredTableData = (inputValue, tableData, searchKey) => {
  const matchedData = tableData.filter((data) => {
    const startsWithData = data[searchKey].toLowerCase().startsWith(inputValue.toLowerCase());
    if (startsWithData) {
      return startsWithData;
    }

    return data[searchKey].toLowerCase().includes(inputValue.toLowerCase());
  });

  return matchedData;
};

/**
 * Map Tooltip
 * @param {object} feature - Country Info
 * @param {string} activeTooltipType - Selected Data Type
 * @returns {object} - returns map tooltip html
 */

export const getMapTooltip = (feature, activeTooltipType) => {
  return (
    <div className='countries-tooltip'>
      <div className='countries-name-tooltip'>{feature.properties.name}</div>
      <div>
        {activeTooltipType}
        {' : '}
        {feature.value ? kFormatter(feature.value) : '0'}
      </div>
    </div>
  );
};

/**
 * Heat Map Tooltip
 * @param {number} value - Total Data
 * @param {string} xKey - X Axis Tick Value
 * @param {string} yKey - Y Axis Tick Value
 * @param {array} chartData - Times of Day Data
 * @returns {object} - returns heat map tooltip html
 */

export const getHeatMapTooltip = (value, xKey, yKey, tooltipData) => {
  const activeXKey = isSmallTablet() ? yKey : xKey;
  const activeYKey = isSmallTablet() ? xKey : yKey;

  const averageSession = (
    (100 * value) /
    tooltipData.reduce((prev, data) => prev + data[activeXKey], 0)
  ).toFixed(1);

  return (
    <div className='heat-map-tooltip'>
      <div>
        {kFormatter(value)} {'sessions on'} {WEEKS[activeYKey]}
        {', between'} {HOURS[activeXKey]}
      </div>
      <div>
        {'%'}
        {averageSession} {'more than the same time average in other days'}
      </div>
    </div>
  );
};

/** The incoming product lists match their id's and create a new list.
 * @param {object} odooProducts - Data list from odoo
 * @param {object} countlyProducts - Data list from countly
 * @returns {object} - returns filtered and sorted products
 */
export const getFilteredProductLogById = (odooProducts, countlyProducts) => {
  const filteredMatchedProducts = odooProducts
    .filter((product) => countlyProducts.find((element) => parseInt(element._id) === product.id))
    .map((product) => {
      const matchedProduct = countlyProducts.find(
        (element) => parseInt(element._id) === product.id,
      );
      return { ...product, ...matchedProduct };
    });

  const slicedProducts = filteredMatchedProducts.slice().sort((a, b) => b.c - a.c);
  return slicedProducts;
};

/** Creates an object according to the filtered data.
 * @param {string} dataType - Data type
 * @param {object} productList - Object to be filtered
 * @returns {object} - returns created object
 */
export const createFilteredCategoryBasedObject = (productList, dataType) => {
  return Object.entries(productList).map(([key, value]) => ({
    name: key,
    [dataType]: value,
  }));
};

/** Filters the incoming object according to the data type.
 * @param {string} dataType - Data type
 * @param {object} productList - Object to be filtered
 * @returns {object} - returns filtered object
 */
export const filteredCategoryBasedData = (productList, dataType) => {
  if (!productList || productList.length === 0) return [];
  const newProductList = [];

  for (const product of productList) {
    const productCountValue = newProductList[`${product.brand_name} / ${product.full_category}`];
    if (!productCountValue) {
      newProductList[`${product.brand_name} / ${product.full_category}`] = product[dataType];
      continue;
    }

    newProductList[`${product.brand_name} / ${product.full_category}`] =
      productCountValue + product[dataType];
  }

  return createFilteredCategoryBasedObject(newProductList, dataType);
};

/** Filters the incoming array according to the data type.
 * @param {array} statisticsData - Array to be filtered
 * @param {string} statisticsType - Data type
 * @returns {array} - returns filtered array
 */
export const getTopStatisticsChartData = (statisticsData, statisticsType) => {
  const topValues = getTopValues({
    data: statisticsData,
    limit: 5,
    type: statisticsType,
    shouldIncludeRest: true,
  });
  return topValues.values
    .filter((value) => value._name !== 'undefined' || value._id !== 'undefined')
    .map((value, key) => ({
      id: value.name ? `${value.name}${value.color_name || ''}` : value._id,
      value: calculatePercentage({
        unit: value[statisticsType],
        total: topValues.total,
        digit: 0,
      }),
      color: ChartColors.TOP_STATISTICS[key],
    }));
};

export const getFormattedDailyData = (dailyData) => {
  if (!dailyData || Object.keys(dailyData).length < 1) return [];

  const formattedData = Object.entries(dailyData)
    .map(([date, data]) => ({
      _id: splitAndJoinDateStr({ date: date.toString(), splitBy: '.', joinBy: '-' }),
      c: data.u,
      t: data.t,
      s: data.s,
      dur: data.dur,
    }))
    .sort((a, b) => new Date(a._id) - new Date(b._id));

  return formattedData;
};
