// ** Initial State
const initialState = {
  allWebsiteStats: {},
  isAllWebsiteLoading: true,
  isLoading: true,
  categoryInterval: 'daily',
  activationFunnel: {},
  isFunnelLoading: true,
};

const statisticReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GOT_ALL_WEBSITE_STATS':
      return { ...state, allWebsiteStats: action.data };
    case 'SET_LOADER':
      return { ...state, isLoading: action.data };
    case 'LOADING_WEBSITE_STATS':
      return { ...state, isAllWebsiteLoading: action.data };
    case 'SET_CATEGORY_INTERVAL':
      return { ...state, categoryInterval: action.data };
    case 'GOT_ACTIVATION_FUNNEL':
      return { ...state, activationFunnel: action.data };
    case 'SET_ACTIVATION_FUNNEL_LOADER':
      return { ...state, isFunnelLoading: action.data };
    default:
      return state;
  }
};

export default statisticReducer;
