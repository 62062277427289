const initialState = {
  products: [],
  isLoading: true,
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GOT_PRODUCTS':
      return {
        ...state,
        products: action.res.data.result.response,
      };
    case 'LOADING_PRODUCTS':
      return {
        ...state,
        isLoading: action.value,
      };
    case 'SET_ACTIVE_PRODUCT_ID':
      return { ...state, activeProductId: action.payload };
    case 'GET_ORIGINAL_PRODUCT_DATA':
      return {
        ...state,
        originalProduct: action.res.data.result.response,
      };
    default:
      return state;
  }
};

export default productsReducer;
